import type { AxiosError } from 'axios'
import axios from 'axios'
import { authRoutes } from './constants'
import { parseServerResponseMessage } from './helpers'
import { addSearchParam } from '@/modules/common/utils/common'
import { showDynamicConfirmModal } from '@/ui-components/UIDynamicConfirmModal/useDynamicConfirmModal'
import type { TypeWarning } from '@/modules/common/utils/notification'
import { showErrorNotify } from '@/modules/common/utils/notification'
import { i18n, t } from '@/modules/i18n'
import { logger } from '@/modules/logger'

type UnauthorizedHandler = (route: string) => Promise<any>

let unauthorizedHandler: UnauthorizedHandler | undefined

export async function setUnauthorizedHandler(handler: UnauthorizedHandler) {
  unauthorizedHandler = handler
}

const axiosInstance = axios.create({
  baseURL: '/',
})

function getTimezoneOffsetString(): string {
  const offset = (new Date()).getTimezoneOffset() / -60

  return offset <= 0 ? offset.toString() : `+${offset}`
}

axiosInstance.interceptors.request.use((config: any) => {
  config.headers['Accept-Language'] = i18n.locale
  config.headers['X-Utc-Offset'] = getTimezoneOffsetString()
  return config
})

axiosInstance.interceptors.response.use(
  async (response) => {
    let result = response

    if (response.status === 222) {
      const routeKey = await showDynamicConfirmModal({
        title: response.data.message,
        options: response.data.options,
      })

      response.config.url = addSearchParam(response.config.url!, { routeKey })
      result = await axiosInstance(response.config)
    }

    return result
  },
  async (error: AxiosError) => {
    const isNotAuthRoute = authRoutes.every(
      path => !error.response?.config?.url?.includes(path),
    )
    const errorCode = error.response?.status

    let errorMessage = await parseServerResponseMessage(error)
    let errorType: TypeWarning = 'warning'

    if (errorCode === 401 && isNotAuthRoute) {
      const route = window.location.pathname + window.location.search
      if (unauthorizedHandler)
        await unauthorizedHandler(route)

      return Promise.reject(error)
    }

    if (errorCode === 500) {
      errorType = 'error'
      errorMessage = errorMessage ?? t('unknownError')
    }

    if (errorCode !== 401) {
      logger.error(error)

      showErrorNotify({
        error,
        message: errorMessage ?? t('baseServerError'),
        type: errorType,
      })
    }

    return Promise.reject(error)
  },
)

export default axiosInstance
